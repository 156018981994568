// https://www.youtube.com/watch?v=8y9wUwJvdGs
// https://codesandbox.io/s/xstate-vue-3-template-vrkk9

import { createMachine } from "xstate";

function hasPermTo(action) {
    return Permissions.indexOf(action) !== -1;
}

export const taskDocumentStateMachine = createMachine({
    id: "paper-workflow",
    initial: "requested",
    states: {
        requested: {
            on: {
                PREPARE: {
                    target: "prepared",
                    cond: () => {
                        return hasPermTo("prepare task-documents");
                    },
                },
            },
        },
        prepared: {
            on: {
                RECALL: {
                    target: "requested",
                    cond: () => {
                        return hasPermTo("request task-documents");
                    },
                },
                DECLINE: {
                    target: "rejected",
                    cond: () => {
                        return hasPermTo("review task-documents");
                    },
                },
                ACCEPT: {
                    target: "reviewed",
                    cond: () => {
                        return hasPermTo("review task-documents");
                    },
                },
            },
        },
        reviewed: {
            on: {
                REJECT: {
                    target: "rejected",
                    cond: () => {
                        return hasPermTo("approve task-documents");
                    },
                },
                APPROVE: {
                    target: "approved",
                    cond: () => {
                        return hasPermTo("approve task-documents");
                    },
                },
            },
        },
        rejected: {
            on: {
                RETRY: {
                    target: "prepared",
                    cond: () => {
                        return hasPermTo("prepare task-documents");
                    },
                },
            },
        },
        approved: {
            type: "final",
        },
    },
});
