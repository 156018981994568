<template>
    <div class="row">
        <div class="col-lg-4 col-md-5">
            <div class="card">
                <div class="card-body">
                    <input type="search" v-model="search" class="form-control" placeholder="Search documents..." />
                    <hr/>
                    <p class="text-muted" v-if="filteredSectionDocuments.length === 0">
                        No documents found...
                    </p>
                    <ul class="sections">
                        <li class="section"
                            v-for="(_section, index) in filteredSectionDocuments"
                            v-bind:key="index"
                            v-on:click.prevent="selectSection(_section, $event)">
                            {{ _section.name }}
                            <ul class="section-documents">
                                <li class="section-document"
                                    v-for="(_document, index) in _section.documents"
                                    v-bind:key="index"
                                    v-on:click.prevent="selectSectionDocument(_document, $event)"
                                    v-bind:class="{ selected: task_document && (task_document.id == _document.id) }">
                                    {{ _document.name }}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-7">
            <div class="card" v-if="this.task">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <h3 class="mb-0">{{ this.task.company.name }} - {{ this.task.period }}</h3>
                        <button type="button" class="btn btn-success btn-sm"
                            v-if="$can('archive tasks')"
                            v-show="! this.task.locked_at"
                            v-on:click="archiveTask">
                            Archive
                        </button>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body" v-if="! this.task_document">
                    <p class="d-block mb-0 text-muted">Choose a document from the list on the left...</p>
                </div>
                <div class="card-body" v-else>
                    <div>
                        <label for="papers" class="form-label fw-semi-bold" :class="{ required: task_document.document.is_mandatory }">
                            {{ this.task_document.document.code }} - {{ task_document.document.name }} [{{ task_document.status }}]
                        </label>

                        <small class="d-block mb-2">{{ this.task_document.document.description  }}</small>
                    </div>
                    <table class="table table-sm table-striped table-borderless table-hover">
                        <tbody>
                            <tr v-for="(paper_url, index) in task_document.paper_urls" v-bind:key="index">
                                <td>
                                    <a target="_blank" class="d-block small my-2" v-bind:href="paper_url">
                                        {{ paper_url | nameFromPath }}
                                    </a>
                                </td>
                                <td class="text-end" v-if="canUploadPapers">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="18" height="18" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-x cp"
                                        v-if="! task.locked_at && ! task_document.approved_at"
                                        v-on:click="deletePaperAtIndex(index, $event)">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <form v-on:submit.prevent="updatePaperStatus" v-on:keydown="document_form.errorBag.clear($event.target.id)">

                        <div class="mb-3">
                            <input type="file" class="form-control" id="papers" ref="papersFileInput" multiple
                                v-on:change="fileChanged"
                                v-bind:disabled="! canUploadPapers"
                                v-bind:class="{'is-invalid' : document_form.errorBag.has('papers')}">

                            <small class="invalid-feedback" role="alert"
                                v-if="document_form.errorBag.has('papers')"
                                v-text="document_form.errorBag.first('papers')"></small>
                        </div>
                        <div class="mb-3">
                            <textarea rows="2" class="form-control" placeholder="Type your remarks..."
                                v-model="document_form.remarks"
                                v-bind:class="{'is-invalid' : document_form.errorBag.has('remarks')}"
                                ></textarea>
                            <small class="invalid-feedback" role="alert"
                                v-if="document_form.errorBag.has('remarks')"
                                v-text="document_form.errorBag.first('remarks')">
                            </small>
                        </div>
                        <div class="row">
                            <div class="col">
                                <select class="form-control"
                                    v-model="document_form.action"
                                    v-bind:class="{'is-invalid' : document_form.errorBag.has('action')}">
                                    <option value="" selected>Trigger action...</option>
                                    <option v-for="(event, index) in allowedNextEvents" v-bind:value="event">
                                        {{ event }}
                                    </option>
                                </select>
                                <small class="invalid-feedback" role="alert" v-if="document_form.errorBag.has('action')"
                                    v-text="document_form.errorBag.first('action')"></small>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button type="submit" class="btn btn-primary btn-sm">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="card" v-if="this.task_document && this.task_document.comments.length">
                <div class="card-body">
                    <p style="border-bottom: 1px solid #eee; padding-bottom: 0.5rem;" 
                        v-for="(comment, index) in this.task_document.comments" v-bind:key="index" v-html="comment.body">
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { objectToFormData } from '../../forms/util';
    import { interpret, State } from 'xstate';
    import { taskDocumentStateMachine } from '../../machines/TaskDocumentStateMachine.js';

    const resolvedState = taskDocumentStateMachine.resolveState(taskDocumentStateMachine.initialState);

    export default {
        name: 'v-task-documents',
        props: {
            taskId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                search: '',
                task: null,
                section: null,
                sections: [],
                task_document: null,
                task_documents: [],
                document_form: new Form({
                    _method: 'PUT',
                    papers: null,
                    remarks: '',
                    action: ''
                }),
                taskDocumentStateService: interpret(taskDocumentStateMachine),
                taskDocumentState: taskDocumentStateMachine.initialState,
            }
        },
        created: function() {
            this.fetchTaskDocuments(this.taskId);
        },
        filters: {
            nameFromPath: function (url) {
                return url ? decodeURIComponent(url.split('#').shift().split('?').shift().split('/').pop()) : '';
            }
        },
        computed: {
            filteredSectionDocuments: function() {
                let app = this;

                let sections = JSON.parse(JSON.stringify(app.sections));

                for (const [idx, section] of Object.entries(sections)) {

                    var matchingDocs = section.documents.filter(function(document) {
                        return document.name.toLowerCase().indexOf(app.search.toLowerCase()) !== -1
                    });

                    section.documents = matchingDocs;
                }

                return sections.filter(function(section) {
                    return section.documents.length;
                });
            },
            allowedNextEvents: function() {
                return this.taskDocumentState.nextEvents.filter((nextEvent) => {
                    return taskDocumentStateMachine.transition(this.taskDocumentState, {
                        type: nextEvent,
                    }).changed;
                });
            },
            canUploadPapers: function() {
                const state = this.taskDocumentState.value;

                var $can = function(permissionName) {
                    return window.Permissions.indexOf(permissionName) !== -1;
                }

                switch (state) {
                    case 'requested': return $can('prepare task-documents');
                    case 'prepared':  return $can('review task-documents');
                    case 'rejected':  return $can('prepare task-documents');
                    case 'accepted':  return $can('approve task-documents');
                    // case 'approved':  return false;
                    default: return false;
                }
            }
        },
        methods: {
            fetchTaskDocuments: function(taskId) {
                let app = this;

                axios
                    .get(route('api.tasks.documents', {task: taskId}))
                    .then(function (response) {
                        // dumpJson(response.data);

                        app.task = response.data.task;
                        app.sections = response.data.sections;
                        app.task_documents = response.data.task_documents;

                        // delete app.task.sections;
                    })
                    .catch(function (error) {
                        dumpJson(error.response.data);
                    });
            },
            selectSection: function(section, event) {
                event.target.classList.toggle('collapsed');

                this.section = section;
            },
            selectSectionDocument: function(_document, event) {
                let app = this;

                var task_document = app.task_documents.find(function (task_document) {
                    return task_document.document.id === _document.id;
                });

                console.log(`${task_document.document.name} --> ${task_document.status}`);

                // .....................................................................................................

                const remoteState = taskDocumentStateMachine.getInitialState(task_document.status);

                const previousState = State.create(remoteState || taskDocumentStateMachine.initialState);

                const resolvedState = taskDocumentStateMachine.resolveState(previousState);

                this.taskDocumentStateService.stop();

                this.taskDocumentStateService
                    .onTransition(state => {
                        this.taskDocumentState = state;
                        console.log(`Transitioned to the '${state.value}' state`);
                    })
                    .start(resolvedState);

                // .....................................................................................................

                this.task_document = task_document;
            },
            updateTaskDocument: function(_task_document) {
                let app = this;

                var idx = app.task_documents.findIndex(function (task_document) {
                    return task_document.id === _task_document.id;
                });

                Vue.set(this.task_documents, idx, _task_document);
            },
            fileChanged: function(event) {
                var files = event.target.files || event.dataTransfer.files;

                if(document.getElementById(event.target.id).multiple) {
                    this.document_form[event.target.id] = files;
                } else {
                    this.document_form[event.target.id] = files[0];
                }

                this.document_form.errorBag.clear(event.target.id);
            },
            updatePaperStatus: function() {
                let app = this;

                let url = route('api.tasks.documents.status', {
                    task: this.task_document.task_id,
                    document: this.task_document.document_id,
                });

                let lastAction = this.document_form.action;

                this.document_form.submit('post', url)
                    .then(function (response) {
                        // dumpJson(response.data);

                        // app.document_form.papers = response.data.papers;

                        app.updateTaskDocument(response.data);
                        app.task_document = response.data;

                        app.taskDocumentStateService.send(lastAction);

                        toastr.success(response.data.document.name, response.data.status);
                    })
                    .catch(function (error) {
                        // console.error(error);

                        toastr.error(app.document_form.errorMessage);
                    });

                this.$refs.papersFileInput.value = null;
            },
            preparePapers: function() {
                let app = this;

                let url = route('api.tasks.documents.prepare', {
                    task: app.task_document.task_id,
                    document: app.task_document.document_id,
                });

                let formData = new FormData();

                formData.append('_method', 'PUT');
                // https://www.laravelcode.com/post/single-and-multiple-files-upload-in-vuejs
                // https://stackoverflow.com/a/40902462/2732184
                for (const i of Object.keys(this.document_form.papers)) {
                    formData.append('papers[]', this.document_form.papers[i])
                }

                axios.post(url, formData)
                    .then(function (response) {
                        app.document_form.reset();

                        app.document_form = new Form({
                            papers: response.data.papers
                        });

                        app.updateTaskDocument(response.data);
                        app.task_document = response.data;

                        toastr.success(`${response.data.document.name}`, 'Prepared');
                    })
                    .catch(function (error) {
                        console.error(error);

                        if(error.response.data.hasOwnProperty('message')) {
                            toastr.error(error.response.data.message);
                        } else {
                            toastr.error(error.response.statusText, error.response.status);
                        }

                        if(error.response.status == 422 && error.response.data.hasOwnProperty('errors')) {
                            app.document_form.errorBag.record(error.response.data.errors);
                        }
                    });

                this.$refs.papersFileInput.value = null;

                this.document_form.errorBag.clear(event.target.id);
            },
            approvePapers: function() {
                let app = this;

                let url = route('api.tasks.documents.approve', {
                    task: app.task_document.task_id,
                    document: app.task_document.document_id,
                });

                let formData = new FormData();

                formData.append('_method', 'PUT');

                axios.post(url, formData)
                    .then(function (response) {
                        app.document_form.reset();

                        app.document_form = new Form({
                            paper: response.data.paper
                        });

                        app.updateTaskDocument(response.data);
                        app.task_document = response.data;

                        toastr.info(`${response.data.document.name}`, 'Approved');
                    })
                    .catch(function (error) {
                        console.error(error);

                        if(error.response.data.hasOwnProperty('message')) {
                            toastr.error(error.response.data.message);
                        } else {
                            toastr.error(error.response.statusText, error.response.status);
                        }

                        if(error.response.status == 422 && error.response.data.hasOwnProperty('errors')) {
                            app.document_form.errorBag.record(error.response.data.errors);
                        }
                    });

                this.document_form.errorBag.clear(event.target.id);
            },
            archiveTask: function() {
                let app = this;

                let url = route('api.tasks.archive', {
                    task: app.task.id,
                });

                let formData = new FormData();

                formData.append('_method', 'PUT');

                axios.post(url, formData)
                    .then(function (response) {
                        app.document_form.reset();

                        app.document_form = new Form();

                        app.task = response.data;

                        toastr.info(`${app.task.name}`, 'Archived');
                    })
                    .catch(function (error) {
                        console.error(error);

                        if(error.response.data.hasOwnProperty('message')) {
                            toastr.error(error.response.data.message);
                        } else {
                            toastr.error(error.response.statusText, error.response.status);
                        }

                        if(error.response.status == 422 && error.response.data.hasOwnProperty('errors')) {
                            app.document_form.errorBag.record(error.response.data.errors);
                        }
                    });

                this.document_form.errorBag.clear(event.target.id);
            },
            deletePaperAtIndex: function(index) {
                if(! confirm("Do you really want to delete?")) {
                    return;
                }

                let app = this;

                let url = route('api.tasks.documents.papers.remove', {
                    task: app.task_document.task_id,
                    document: app.task_document.document_id,
                });

                let formData = new FormData();

                formData.append('_method', 'DELETE');
                formData.append('index', index);

                axios.post(url, formData)
                    .then(function (response) {
                        app.updateTaskDocument(response.data);
                        app.task_document = response.data;

                        toastr.info('...', 'Paper removed');
                    })
                    .catch(function (error) {
                        if(error.response.data.hasOwnProperty('message')) {
                            toastr.error(error.response.data.message);
                        } else {
                            toastr.error(error.response.statusText, error.response.status);
                        }
                    });
            }
        }
    }
</script>

<style lang="css" scoped>
    /* Document tree */

    ul.sections {
        height: calc(100vh - 250px);
        overflow-y: auto;
        list-style: none;
    }

    ul.sections > li {
        font-weight: 500;
        margin: 15px 0;
    }

    ul.sections > li:hover {
        cursor: pointer;
    }

    li.section.collapsed > ul {
        display: none;
    }

    ul.section-documents {
        list-style: circle;
        list-style-type: "→";
        padding-left: 10px;
    }

    ul.section-documents > li {
        font-weight: normal;
        margin: 10px 0;
        padding-left: 10px;
    }

    ul.section-documents > li:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    ul.section-documents > li.selected {
        font-weight: bold;
    }

    /* Utilities */

    .fw-semi-bold {
        font-weight: 500;
    }

    label.required::after {
      color: #dc3545;
      content: " *";
    }

    .table td, .table th {
        padding: 5px 10px!important;
        font-weight: 300;
    }

    .cp {
        cursor: pointer;
    }
</style>
